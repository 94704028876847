<template>
<div class="coupon-wrapper coupon-single featured">

    <div class="row">
        <div v-if="featured" class="ribbon-wrapper hidden-xs">
            <div class="ribbon">Featured</div>
        </div>
        <div class="coupon-data col-sm-2 text-center">
            <div class="savings text-center">
                <div class="pointer" @click="redirectToStore">
                    <img v-if="carddetail.store_id.image" :src="$base_url + carddetail.store_id.image" class="img-thumbnail" :alt="carddetail.store_id.name" />
                    <img v-else class="img-responsive" src="../../assets/placeholder.png" :alt="carddetail.store_id.name" />
                    <!-- <div class="large">%</div>
                        <div class="small">off</div> -->
                    <div class="type">{{ carddetail.store_id.name }}</div>
                </div>
            </div>

            <!-- end:Savings -->
        </div>
        <!-- end:Coupon data -->
        <div class="coupon-contain col-sm-7">
            <h4 class="coupon-title">
                <a href="javascript://" @click="redirectToStore">
                    {{ carddetail.title }}
                </a>
            </h4>
            <p data-toggle="collapse">
                {{ description(carddetail) }}
            </p>
            <!-- end:Coupon details -->
        </div>
        <!-- end:Coupon cont -->
        <div class="button-contain col-sm-3 text-center">
            <p class="btn-code" data-toggle="modal" data-target=".couponModal" @click="openGetCode">
                <span class="partial-code">BTSBAGS</span>
                <span class="btn-hover">Get Code</span>
            </p>
        </div>
    </div>
    <!-- //row -->
</div>
</template>

<script>
export default {
    name: "CouponCard",
    props: ["carddetail", "featured"],
    methods: {
        openGetCode() {
            this.$emit("click-get-code", this.carddetail);
        },
        redirectToStore() {
            this.$router.push({
                name: 'SearchStore',
                params: {
                    code: this.carddetail.store_id._id,
                    slug: this.store_id.slug
                },
            })
        },
        // description(data) {
        //     if (data.hasOwnProperty('description')) {
        //         return data.description.length > 244 ? data.description.slice(0, 113) + '...' : data.description || "-"
        //     }
        //     return "..."
        // },
        description(data) {
            if (data.hasOwnProperty('description')) {
                // Remove HTML tags and entities
                let cleanDescription = data.description
                    .replace(/<[^>]+>/g, '') // Remove HTML tags
                    .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
                    .replace(/&amp;/g, '&')  // Replace &amp; with &
                    .replace(/&lt;/g, '<')   // Replace &lt; with <
                    .replace(/&gt;/g, '>');  // Replace &gt; with >

                // Truncate if necessary
                return cleanDescription.length > 244 ? cleanDescription.slice(0, 113) + '...' : cleanDescription || "-";
            }
            return "...";
        },
    },
}
</script>

<style scoped>

</style>
